<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reclamaciones')+': '+$t('Vehículo')}} </h3>
		</div>
		<div class="p-col-12" v-if="!mostrar">
			<div class="card">
                <DataTable :value="claims" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Filtrar()"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado!=2 && i18n.locale() == 'es'">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado!=2 && i18n.locale() == 'en'">
								{{slotProps.data.estado_name}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado==2 && i18n.locale() == 'es'">
								{{slotProps.data.estado2_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado==2 && i18n.locale() == 'en'">
								{{slotProps.data.estado2_name}}
							</strong>
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Forence')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.forenc}}<br>
							<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('Pendiente')" v-if="slotProps.data.forence"></Tag>
                        </template>
                    </Column>
					<Column :header="$t('Acciones')" headerStyle="width: 80px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="Editar(slotProps.data)" v-if="edit  && slotProps.data.estado==11"/>
							<Button icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.estado==13"/> 
						</template>
					</Column>

                </DataTable>
			</div>
        </div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Póliza')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-4">
								{{$t('Número')}}: <strong>{{seleccion.poliz.codigo}}</strong>			
							</div>
							<div class="p-col-2">
								{{$t('Cliente')}}: <strong>{{seleccion.cliente}}</strong>			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<strong v-if="seleccion.vehiculo">{{seleccion.vehiculo.poliza_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<strong v-if="seleccion.vehiculo">{{seleccion.vehiculo.poliza_name}}</strong> 			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.poliz.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.poliz.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.poliz.estado==6"/>
								<strong>{{seleccion.poliz.estado_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.poliz.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.poliz.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.poliz.estado==6"/>
								<strong>{{seleccion.poliz.estado_name}}</strong> 			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Información Básica')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-2">
								{{$t('Número ID')}}: <strong>{{seleccion.code}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Localidad')}}: <strong v-if="seleccion.vehiculo">{{seleccion.vehiculo.localidadn}}</strong>			
							</div>
							<div class="p-col-7">
								{{$t('Nombre Completo')}}: <strong>{{seleccion.nombre}}</strong>			
							</div>
							<div class="p-col-6">
								{{$t('Correo')}}: <strong>{{seleccion.correo}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono móvil')}}: <strong>{{seleccion.celular}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono privado')}}: <strong>{{seleccion.tlflocal}}</strong>			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Beneficiario')+' '+(index+1)" v-for="(item, index) in seleccion.beneficiarios" :key="index">
					<h4>{{$t('Forence')}}</h4>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-8">
									{{$t('Nombre del Taller')}}: <strong>{{item.taller}}</strong> 			
								</div>
								<div class="p-col-1">
									{{$t('Nº Días')}}: <strong>{{item.dias}}</strong> 			
								</div>
								<div class="p-col-3">
									{{$t('Fecha')}}: <strong>{{seleccion.forence_fecha}}</strong> 			
								</div>
								<br><br>
								<div class="p-col-2">
									{{$t('Observaciones')}}:	
								</div>
								<div class="p-col-10">
									<strong>{{item.observacion}}</strong> 			
								</div>
							</div>
							<br><br>
							<div class="p-grid">
								<div class="p-col-8">
									<div class="p-grid">
										<div class="p-col-12">
											<DataTable :value="item.forence" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
												:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
												<Column :header="$t('Descripción')">
													<template #body="slotProps">
														{{slotProps.data.descripcion}}<br>
														<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('De segunda')" v-if="slotProps.data.secunda"></Tag>
													</template>
												</Column>
												<Column :header="$t('Costo')">
													<template #body="slotProps">
														{{slotProps.data.costo}}
													</template>
												</Column>
												<Column :header="$t('Cantidad')">
													<template #body="slotProps">
														{{slotProps.data.cantidad}}
													</template>
												</Column>
												<Column :header="$t('Total')">
													<template #body="slotProps">
														{{slotProps.data.costo*slotProps.data.cantidad}}
													</template>
												</Column>
											</DataTable>
										</div>
										<br><br>
										<div class="p-col-8">
											<strong>Subtotal:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.subtotal).toFixed(2)}}</strong>	
										</div>
										<br><br>
										<div class="p-col-4">
											<strong>{{$t('Impuesto')}}:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.impuesto).toFixed(2)}}%</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{(parseFloat(item.subtotal)*parseFloat(item.impuesto/100)).toFixed(2)}}</strong> 			
										</div>
										<br><br>
										<div class="p-col-8">
											<strong>{{$t('Menos Riesgo Propio')}}:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.descuento).toFixed(2)}}</strong> 			
										</div>
										<br><br>
										<div class="p-col-4">
											<strong>{{$t('Tiempo perdido')}}:</strong> 	
										</div>
										<div class="p-col-4">
											<strong v-if="item.dias">{{item.dias}} * {{parseFloat(item.timeloss).toFixed(2)}}</strong>
											<strong v-else>0 * {{parseFloat(item.timeloss).toFixed(2)}}</strong>
										</div>
										<div class="p-col-4">
											<strong v-if="item.dias">{{(parseFloat(item.dias)*parseFloat(item.timeloss)).toFixed(2)}}</strong> 			
											<strong v-else>{{(0*parseFloat(item.timeloss)).toFixed(2)}}</strong> 			
										</div>
										<br><br>
										<div class="p-col-8">
											<strong>{{$t('Labor de Trabajo')}}:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.trabajo).toFixed(2)}}</strong> 			
										</div>
										<br><br>
										<div class="p-col-8">
											<strong>{{$t('Labor de Pintura')}}:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.pintura).toFixed(2)}}</strong> 			
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<DataTable :value="item.anexos" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
										:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
										<Column :header="$t('Documentos')">
											<template #body="slotProps">
												{{slotProps.data.descripcion}}<br>
												<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('De segunda')" v-if="slotProps.data.secunda"></Tag>
											</template>
										</Column>
										<Column :header="$t('Ver')">
											<template #body="slotProps">
												<img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
												<img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
											</template>
										</Column>
									</DataTable>
									<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
										<img style="width: 350px;" :src="imagen"/>
									</OverlayPanel>														
								</div>
								<br><br>
								<div class="p-col-12"><br><br>
									<div class="p-grid">
										<div class="p-col-5">
											<h3><strong>{{$t('Total')}}:</strong></h3>
										</div>
										<div class="p-col-2">
											<h3><strong>{{parseFloat(item.total).toFixed(2)}}</strong></h3>	
										</div>
										<div class="p-field p-col-2">
											<strong>{{$t('Documento de Aceptación')}}</strong>
										</div>
										<div class="p-field p-col-2">
											<img style="width: 75px;" src="images/pdf.jpg" v-if="item.aceptacion" @click="VerPDF(item.aceptacion)"/>
										</div>
									</div>								
								</div>
								<br><br>
							</div>
						</div>
					</div>
				</AccordionTab>
			</Accordion>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card" v-if="seleccion.forence_user>0 && seleccion.anexos2.length>0 && seleccion.lesiones">
				<Card>
					<template #title>
						<h4>{{$t('Informe de Lesiones')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-12">
								<DataTable :value="seleccion.anexos2" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
										:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
									<Column :header="$t('Descripción')">
										<template #body="slotProps">
											{{slotProps.data.descripcion}}
										</template>
									</Column>
									<Column :header="$t('Ver')">
										<template #body="slotProps">
											<img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
											<img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
										</template>
									</Column>
								</DataTable>
								<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
									<img style="width: 350px;" :src="imagen"/>
								</OverlayPanel>										
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Estatus')}}:  
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado!=2 && i18n.locale() == 'es'">
								{{seleccion.estado_nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado!=2 && i18n.locale() == 'en'">
								{{seleccion.estado_name}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado==2 && i18n.locale() == 'es'">
								{{seleccion.estado2_nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado==2 && i18n.locale() == 'en'">
								{{seleccion.estado2_name}}
							</strong>
						</h4>
					</template>
					<template #content>	
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12" v-if="seleccion.recuperacion">
								<strong style="color: red;">{{$t('Aplica recuperación')}}!</strong>
							</div>
							<div class="p-field p-col-12">
								<DataTable :value="seleccion.beneficiarios" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
										:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
									<Column :header="$t('Beneficiario')">
										<template #body="slotProps">
											<div v-if="slotProps.data.taller_activo">
												{{slotProps.data.taller}}
											</div>
											<div v-else>
												{{slotProps.data.nombre}}
											</div>
										</template>
									</Column>
									<Column :header="$t('Fecha')">
										<template #body="slotProps">
											<InputMask v-model="slotProps.data.ref_fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" :disabled="seleccion.estado==13 || slotProps.data.pagado" @change="Validar()"/>
											<!--<InputText v-model="slotProps.data.ref_fecha" type="date" :disabled="seleccion.estado==13 || slotProps.data.pagado" @change="Validar()"/>-->
										</template>
									</Column>
									<Column :header="$t('Banco')">
										<template #body="slotProps">
											<Dropdown v-model="slotProps.data.banco" :options="bancos" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" :disabled="seleccion.estado==13 || slotProps.data.pagado" @change="Validar()"/>
										</template>
									</Column>
									<Column :header="$t('Referencia')">
										<template #body="slotProps">
											<InputText v-model="slotProps.data.referencia" type="text" :disabled="seleccion.estado==13 || slotProps.data.pagado" @keyup="Validar()"/>
										</template>
									</Column>
									<Column :header="$t('Monto')">
										<template #body="slotProps">
											<InputText v-model="slotProps.data.total" type="number" disabled/>
										</template>
									</Column>
									<Column :header="$t('Pagado')">
										<template #body="slotProps">
											<Checkbox v-model="slotProps.data.pagado" :binary="true" :disabled="seleccion.estado>11 || slotProps.data.ref_fecha==null || slotProps.data.banco==null || slotProps.data.referencia==null || slotProps.data.referencia==''"  @click="Calcular(slotProps.data)"/>
										</template>
									</Column>
								</DataTable>
							</div>
							<div class="p-field p-col-3">
								<strong>{{$t('Menos Riesgo Propio')}}:</strong> 
							</div>
							<div class="p-field p-col-3">
								<InputNumber id="horizontal" v-model="seleccion.descuento" showButtons buttonLayout="horizontal" 
									decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
									decrementButtonIcon="pi pi-minus" min="0" mode="currency" :currency="$store.state.currency" style="max-width:200px;"/>
							</div>
							<div class="p-field p-col-6">
								<InputSwitch v-model="seleccion.deduc_pagado" />
							</div>
							<div class="p-field p-col-12" v-if="seleccion.lesiones">
								<strong>{{$t('Lesiones personales')}}</strong>
								<DataTable :value="[lesion]" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
										:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
									<Column :header="$t('Beneficiario')">
										<template #body="slotProps">
												{{slotProps.data.nombre}}
										</template>
									</Column>
									<Column :header="$t('Fecha')">
										<template #body="slotProps">
											<InputText v-model="slotProps.data.fecha" type="date" :disabled="seleccion.estado==13 || slotProps.data.pagado" @change="Validar()"/>
										</template>
									</Column>
									<Column :header="$t('Banco')">
										<template #body="slotProps">
											<Dropdown v-model="slotProps.data.banco" :options="bancos" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" :disabled="seleccion.estado==13 || slotProps.data.pagado" @change="Validar()"/>
										</template>
									</Column>
									<Column :header="$t('Referencia')">
										<template #body="slotProps">
											<InputText v-model="slotProps.data.referencia" type="text" :disabled="seleccion.estado==13 || slotProps.data.pagado" @keyup="Validar()"/>
										</template>
									</Column>
									<Column :header="$t('Monto')">
										<template #body="slotProps">
											<InputText v-model="slotProps.data.monto" type="number" disabled/>
										</template>
									</Column>
									<Column :header="$t('Pagado')">
										<template #body="slotProps">
											<Checkbox v-model="slotProps.data.pagado" :binary="true" :disabled="seleccion.estado>11  || slotProps.data.fecha==null || slotProps.data.banco==null || slotProps.data.referencia==null || slotProps.data.referencia==''" @click="Calcular2(slotProps.data)"/>
										</template>
									</Column>
								</DataTable>
							</div>
							<div class="p-field p-col-12">
								<h4>{{$t('Pagado')}}: <strong>{{moneda(pagado)}}</strong></h4>
							</div>
							<div class="p-field p-col-12">
								<h4>{{$t('Pagos Pendientes')}}: 
									<strong v-if="seleccion.lesiones">{{moneda(parseFloat(seleccion.total)+parseFloat(seleccion.lesmonto)-parseFloat(pagado)-parseFloat(seleccion.descuento))}}</strong>
									<strong v-else>{{moneda(parseFloat(seleccion.total)-parseFloat(pagado)-parseFloat(seleccion.descuento))}}</strong>
								</h4>
							</div>
							<h3 v-if="seleccion.lesiones"><strong>{{$t('Total')}}: {{moneda(parseFloat(seleccion.total)+parseFloat(seleccion.lesmonto)-parseFloat(seleccion.descuento))}}</strong></h3>
							<h3 v-else><strong>{{$t('Total')}}: {{moneda(parseFloat(seleccion.total)-parseFloat(seleccion.descuento))}}</strong></h3>
						</div>
					</template>
					<template #footer>
						<Button :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" v-if="seleccion.estado==11" @click="Guardar(true)"/>
						<Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="mostrar=false"/>
						<Button :label="$t('Enviar a Reclamaciones')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" @click="Guardar(false)" v-if="seleccion.estado==11 && pagado==0"/>
					</template>
				</Card>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: {nombre: 'Todos', name: 'All', code: 0, color: ''},
				opciones: [{nombre: 'Todos', name: 'All', code: 0, color: ''}],
				resultado: [],
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				polizas: [],
				seleccion: {},
				mostrar: false,
				forences: [],
				tipos: [],
				estados: [],
				validationErrors: {},
				estado: null,
				estado2: null,
				claims: [],
				bancos: [],
				lesion: {},
				pdf: null,
				lpdf: null,
				completo: false,
				pagado: 0,
			}
		},
		created() {
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Validar(){
				var result = true;
				this.seleccion.beneficiarios.forEach(element => {
					if(element.ref_fecha==null || element.banco == null || element.referencia == null || element.referencia == ''){
						result = false;
					}
				});
				this.completo = result;
			},
			Mostrar() {
				this.$store.commit('Loading');
				this.polizas = [];
				this.mostrar = false;
				this.pagado = 0;
				const Consulta = new API('Reclamos');
				Consulta.Ini('Claim2').then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					if(result.consult.polizas){
						this.polizas = result.consult.polizas;
					}
					if(result.consult.forences){
						this.forences = result.consult.forences;
					}
					if (result.consult.tipos){
						this.tipos = result.consult.tipos;
					}    					
					if (result.consult.claimsadm){
						this.claims = result.consult.claimsadm;
						this.resultado = result.consult.claimsadm;
					}
					if (result.consult.opciones){
						result.consult.opciones.forEach(element => {
							this.opciones.push(element);
						});
					}
					this.$store.commit('Loading');
				}); 
			},
			Filtrar() {		
				if (this.opcion.code==0){
					this.claims = this.resultado;
				} else {
					this.claims = this.resultado.filter(val => val.estado == this.opcion.code);			
				}
			},
			Editar(data) {
				this.$store.commit('Loading');
				this.seleccion = {};
				this.pagado = 0;
				this.lesion = {};
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim2',{
					caso: 	'MostAdmin',
					id: data.id,
					}).then(response => {
						if (response.result.claimsadm){
							this.seleccion = response.result.claimsadm;	
							this.estados.forEach(element => {
								if(this.seleccion.estado==element.code){
									this.estado = element;
								}
							});
							this.bancos = response.result.bancos;
							for (var i = 0; i < this.seleccion.beneficiarios.length; i++) {
								this.bancos.forEach(element => {
									if(this.seleccion.beneficiarios[i].banco==element.code){
										this.seleccion.beneficiarios[i].banco = element;
									}
								});
								if(this.seleccion.beneficiarios[i].ref_fecha){
									this.seleccion.beneficiarios[i].ref_fecha = Consulta.fstring(this.seleccion.beneficiarios[i].ref_fecha);
								}
								if (this.seleccion.beneficiarios[i].pagado){
									this.pagado = parseFloat(this.pagado)+parseFloat(this.seleccion.beneficiarios[i].total);
								}
							}
							if(this.seleccion.lesiones){
								this.lesion = {
									nombre: this.seleccion.poliz.nombre,
									fecha: this.seleccion.les_fecha,
									banco: this.seleccion.les_banco,
									referencia: this.seleccion.les_referencia,
									pagado:  this.seleccion.les_pagado,
									monto: this.seleccion.lesmonto
								};
								this.bancos.forEach(element => {
									if(this.lesion.banco==element.code){
										this.lesion.banco = element;
									}
								});
								if (this.seleccion.les_pagado){
									this.pagado = parseFloat(this.pagado)+parseFloat(this.seleccion.lesmonto);
								}					
							}
							
							this.mostrar = true;
						}
						this.$store.commit('Loading');
					});
			},
			Calcular(data){
				if (!data.pagado){
					this.pagado = parseFloat(this.pagado)+parseFloat(data.total);
				} else {
					this.pagado = parseFloat(this.pagado)-parseFloat(data.total);
				}
			},
			Calcular2(data){
				if (!data.pagado){
					this.pagado = parseFloat(this.pagado)+parseFloat(data.monto);
				} else {
					this.pagado = parseFloat(this.pagado)-parseFloat(data.monto);
				}
			},
			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
			VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
			myUploader1(pdf) {
				this.$store.commit('Loading');
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim2',{
					caso: 	'RevPDF',
					foto: pdf,
				}).then(response => {
					if(response.result){					
						this.informacion.lesionespdf = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			myUploader2(pdf) {
				this.$store.commit('Loading');
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim2',{
					caso: 	'RevPDF',
					foto: pdf,
				}).then(response => {
					if(response.result){					
						this.informacion.aceptacion = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			Guardar(validacion) {
					this.$store.commit('Loading');
					const Consulta = new API('Reclamos');          
					for (var i = 0; i < this.seleccion.beneficiarios.length; i++) {
						if(this.seleccion.beneficiarios[i].ref_fecha){
							this.seleccion.beneficiarios[i].ref_fecha = Consulta.fdate(this.seleccion.beneficiarios[i].ref_fecha);
						}
					}
					Consulta.Procesar('Claim2',{
						caso: 	'Admin',
						claim: this.seleccion,
						estado: this.estado,
						lesion: this.lesion,
						pagado: this.pagado,
						validacion: validacion
					}).then(response => {
						if (response.result) {
							this.$toast.add({
								severity:'success',
								summary: this.$t('Éxito'), 
								detail: this.$t('Información Procesada'), 
								life: 3000
							});
							this.$store.commit('Loading');
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error'), 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
			},
			validateForm() {
				if (this.informacion.fecha==null)
				this.validationErrors['fecha'] = true;
				else
				delete this.validationErrors['fecha'];
				if (this.informacion.reserva==null)
				this.validationErrors['reserva'] = true;
				else
				delete this.validationErrors['reserva'];
				if (this.informacion.forence==null)
				this.validationErrors['forence'] = true;
				else
				delete this.validationErrors['forence'];

				return !Object.keys(this.validationErrors).length;
			},
			moneda(number){
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
